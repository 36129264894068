import React from "react";

export default function Solution({ image, title, solutions }) {
  return (
    <div className="flex flex-col justify-center items-center mx-28 max-sm:mb-10">
      <img src={image} alt="" className="w-20" />
      <h3 className="text-white text-xl font-medium mt-5">{title}</h3>
      <ul className="text-white text-sm mt-2 flex flex-col items-center">
        {solutions.map((solution) => (
          <li key={solution}>{solution}</li>
        ))}
      </ul>
    </div>
  );
}
