import React from "react";
import Solution from "./Solution";
import desarrolloS from "../assets/desarrolloS.webp";
import business from "../assets/business.webp";
import dominioCo from "../assets/dominioCo.webp";

export default function TechSolutions() {
  return (
    <div className="w-full py-20 bg-black flex justify-center items-center">
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-white text-5xl font-medium">
          Soluciones <span className="text-[#D0F344]">Tecnológicas</span>
        </h2>
        <p className="text-white text-center w-2/3 text-lg mt-9">
          En una era donde la tecnología impulsa el éxito, te ofrecemos
          soluciones tecnológicas a medida que optimizan cada aspecto de tus
          operaciones. Ya sea mediante el desarrollo de software personalizado o
          la integración de sistemas que mejoren la eficiencia, estamos aquí
          para ayudarte a innovar y mantener tu empresa a la vanguardia.
        </p>
        <div className="flex justify-center items-center flex-wrap my-20">
          <Solution
            image={desarrolloS}
            title={"DESARROLLO DE SOFTWARE"}
            solutions={[
              "Aplicaciones web responsivas",
              "Aplicaciones móviles",
              "Landing pages",
              "Mantenimiento de sitio web",
            ]}
          />
          <Solution
            image={business}
            title={"BUSINESS INTELLIGENCE"}
            solutions={["Dashboards Personalizados", "Reporting automatizado"]}
          />
          <Solution
            image={dominioCo}
            title={"DOMINIO Y SERVICIO DE CORREO \n EMPRESARIAL"}
            solutions={[
              "Registro de dominios",
              "Configuracion de correo personalizado",
              "Gestión de correo personalizado",
              "Integracion con herramientas colaborativas",
            ]}
          />
        </div>
      </div>
    </div>
  );
}
