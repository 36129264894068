import React from "react";
import Solution from "./Solution";
import diseñoB from "../assets/diseñoB.webp";
import fotografia from "../assets/fotografia.webp";
import motion from "../assets/motion.webp";
import estrategiaP from "../assets/estrategiaP.webp";
import gestionR from "../assets/gestionR.webp";

export default function GraphicSolutions() {
  return (
    <div className="w-full py-20 bg-black flex justify-center items-center">
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-white text-5xl font-medium">
          Soluciones <span className="text-[#FC00C2]">Gráficas</span>
        </h2>
        <p className="text-white text-center w-2/3 text-lg mt-9">
          En un mundo donde la imagen lo es todo, te ofrecemos soluciones
          gráficas que transformarán la forma en que tu marca se presenta ante
          el público. Desde la creación de identidades visuales impactantes
          hasta el posicionamiento de tu marca, estamos aquí para ayudarte a
          captar la atención de tus clientes y comunicar eficazmente el valor de
          tu negocio.
        </p>
        <div className="flex justify-center items-center flex-wrap my-20">
          <Solution
            image={diseñoB}
            title={"DISEÑO Y BRANDING"}
            solutions={[
              "Diseño Gráfico",
              "Identidad corporativa",
              "Ilustracion digital y análogica",
            ]}
          />
          <Solution
            image={fotografia}
            title={"FOTOGRAFÍA Y VIDEO"}
            solutions={[
              "Fotografía profesional",
              "Video (videoclips musicales, cortometrajes, etc)",
              "Edición postproducción",
            ]}
          />
          <Solution
            image={motion}
            title={"MOTION GRAPHIC"}
            solutions={[
              "Animación 2D y 3D",
              "Animación tradicional",
              "Animación Stop Motion",
            ]}
          />
          <Solution
            image={estrategiaP}
            title={"ESTRATEGIA Y PUBLICIDAD"}
            solutions={[
              "Campañas publicitarias",
              "Estrategias de marketing personalizadas",
              "Campañas de FB e IG Ads",
            ]}
          />
          <Solution
            image={gestionR}
            title={"GESTIÓN DE REDES SOCIALES"}
            solutions={[
              "Estrategia de contenido social",
              "Publicación y monetización",
              "Contenido multimedia",
            ]}
          />
        </div>
      </div>
    </div>
  );
}
